import axios from 'axios';

const request = axios.create({
    baseURL: process.env.VUE_APP_REMOTE_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
})

request.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


request.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token') || localStorage.getItem('customer_token') || localStorage.getItem('vendor_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';

    return config;
});

export default request;