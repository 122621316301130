import request from "@/helpers/request";

export function getCountries(){
    return request(
        {
            url: 'api/general/countries',
            method: 'get'
        }
    )
}

export function getCities(){
    return request(
        {
            url: 'api/general/cities',
            method: 'get'
        }
    )
}

export function getCategories(params){
    return request(
        {
            url: 'api/general/categories',
            method: 'get',
            params
        }
    )
}

export function getModels(params){
    return request(
        {
            url: 'api/general/products/models',
            method: 'get',
            params
        }
    )
}

export function getCategory(id){
    return request(
        {
            url: 'api/general/categories/show?category_id='+id,
            method: 'get'
        }
    )
}
export function getSubCategories(category_id){
    return request(
        {
            url: `api/general/sub_categories?q&per_page=10&category_id=${category_id}`,
            method: 'get'
        }
    )
}
export function getSubCategoriesPaginate(params){
    return request(
        {
            url: `api/general/sub_categories`,
            method: 'get',
            params
        }
    )
}

export function getVersions(params){
    return request(
        {
            url: `api/general/products/versions`,
            method: 'get',
            params
        }
    )
}


export function getBrands(){
    return request(
        {
            url: 'api/general/brands',
            method: 'get'
        }
    )
}

export function getBanks(params){
    return request(
        {
            url: 'api/general/banks?q&per_page=20',
            method: 'get',
            params
        }
    )
}