export default {
    state: {
        selectedAddress: ''
    },
    getters: {
        selectedAddress: state => state.selectedAddress
    },
    mutations: {
        SET_SELECTED_ADDRESS(state, data) {
            state.selectedAddress = data
        },
    },
    actions: {
        changeSelectedAddress({commit},data){
            commit('SET_SELECTED_ADDRESS',data)
        }
    },
}