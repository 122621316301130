import request from '@/helpers/request';

export function getCartProducts(page = 1) {
    return request(
        {
            method:'get',
            url: `api/customers/carts?q&per_page=10&page=${page}`,
        }
    )
}

export function addToCart(data) {
    return request(
        {
            method: 'post',
            url: 'api/customers/carts/store',
            data
        }
    )
}

export function updateCart(data) {
    return request(
        {
            method: 'post',
            url: 'api/customers/carts/update',
            data
        }
    )
}
export function deleteCart(data) {
    return request(
        {
            method: 'post',
            url: 'api/customers/carts/destroy',
            data
        }
    )
}