export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "plattenStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلاتن للمتاجر"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
  "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد كتابة كلمة المرور الجديدة"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الحساب"])},
  "signup_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مدير المتجر"])},
  "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تمتلك حساباً بالفعل؟"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "don'tHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تمتلك حساباً بالفعل؟"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
  "createNewAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل حساب جديد"])},
  "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
  "isEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني الذي أدخلته غير صحيح"])},
  "passwordMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمتا المرور غير متطابقتين"])},
  "generalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ,الرجاء المحاولة مرة أخرى"])},
  "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في اسم المستخدم أو كلمة المرور"])},
  "registerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني الذي أدخلته مُستخدم من قبل"])},
  "fillAllFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تعبئة جميع الحقول"])},
  "personal_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات شخصية"])},
  "personal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العناوين الخاصة بك"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
  "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم المستعار"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
  "backTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة إلى"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحصائيات"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد القائم"])},
  "total_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المبيعات"])},
  "total_profits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الأرباح"])},
  "month_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات هذا الشهر"])},
  "month_profits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرباح هذا الشهر"])},
  "month_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات الشهر"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب"])},
  "latestOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخر طلبات الشراء"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
  "addNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة منتج جديد"])},
  "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف منتج جديد"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة"])},
  "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنتج"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرباح"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "remainingProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات المتبقية"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعل"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف الرئيسي"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
  "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف الفرعي"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البراند"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطراز"])},
  "cost_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الشراء"])},
  "profit_rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المربح"])},
  "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقاس"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خصم"])},
  "viewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة المنتج"])},
  "backToProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع لكل المنتجات"])},
  "savedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحفظ بنجاح"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جارِ الحفظ ..."])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "deleteProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المنتج"])},
  "addCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تصنيف"])},
  "addSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تصنيف فرعي"])},
  "countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدول"])},
  "cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدن"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
  "addBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة برند"])},
  "addColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة لون"])},
  "addSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مقاس"])},
  "addDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خصم"])},
  "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة طلب"])},
  "addCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة دولة"])},
  "countryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدولة"])},
  "addCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مدينة"])},
  "editCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مدينة"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر الدولة"])},
  "selectCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر المدينة"])},
  "cityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المدينة"])},
  "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الدولة"])},
  "countryFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شعار الدولة"])},
  "editCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الدولة"])},
  "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البراندات"])},
  "editBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل البرند"])},
  "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأدوات"])},
  "selectCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر التصنيف"])},
  "selectSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر التصنيف الفرعي"])},
  "brandName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البرند"])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشتراكات"])},
  "subscriptionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الباقة"])},
  "monthlyPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الاشتراك الشهري"])},
  "yearlyPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الاشتراك السنوي"])},
  "addSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة باقة"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "editSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الباقة"])},
  "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البنوك"])},
  "addBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة بنك"])},
  "editBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل البنك"])},
  "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم البنك"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
  "shipping-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسه وأسعار الشحن"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])},
  "addFaq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة سؤال جديد"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال"])},
  "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجواب"])},
  "no_data_to_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تقم بتعديل أي بيانات"])}
}