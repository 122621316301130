import request from "@/helpers/request";

export function getSubscriptions(per_page = 2) {
    return request(
        {
            url: `api/general/subscriptions?q&per_page=${per_page}`,
            method: 'get'
        }
    )
}

export function getSubscriptionServices(id) {
    return request(
        {
            url: `/api/general/subscriptions/services?q&per_page=12&subscription_id=${id}`,
            method: 'get'
        }
    )
}

export function subscribePlan(data) {
    return request(
        {
            url: '/api/vendors/subscriptions/subscribe',
            method: 'post',
            data
        }
    )
}

export function getMyPlan(){
    return request(
        {
            url: 'api/vendors/subscriptions/my_subscription',
            method: 'get'
        }
    )
}