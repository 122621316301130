import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store'

const routes = [
{
        path: '/vendor',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Vendor/'),

        children: [
            {
                path: '',
                name: 'vendorHome',
                component: () => import(/* webpackChunkName: "Home" */ '../views/Vendor/Home'),
                meta: {
                    requiresAuth: false,
                    userType: 'vendor'
                },

            },
            {
                path: 'sign-up',
                name: 'sign-up',
                component: () => import(/* webpackChunkName: "Login" */ '../views/Vendor/SignUp.vue'),
                meta: {
                    requiresAuth: false,
                    userType: 'vendor'
                }
            },
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "Login" */ '../views/Vendor/Login.vue'),
                meta: {
                    requiresAuth: false,
                    userType: 'vendor'
                }
            },
            {
                path: 'forget-password',
                name: 'ForgetPassword',
                component: () => import(/* webpackChunkName: "ForgetPassword" */ '../views/Vendor/ForgetPassword.vue'),
                meta: {
                    requiresAuth: false,
                    userType: 'vendor'
                }
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/Vendor/ResetPassword.vue'),
                meta: {
                    requiresAuth: false,
                    userType: 'vendor'
                }
            },
            {
                path: 'plans',
                name: 'Plans',
                component: () => import(/* webpackChunkName: "Plans" */ '../views/Vendor/Plans'),
                meta: {
                    requiresAuth: false,
                    userType: 'vendor'
                }
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Vendor/Dashboard'),
                meta: {
                    requiresAuth: true,
                    userType: 'vendor'
                },
                children: [
                    {
                        path: '',
                        name: 'DashboardHome',
                        component: () => import(/* webpackChunkName: "DashboardHome" */ '../views/Vendor/Dashboard/Home'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'products',
                        name: 'DashboardProducts',
                        component: () => import(/* webpackChunkName: "Products" */ '../views/Vendor/Dashboard/Products'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'add-product',
                        name: 'AddProduct',
                        component: () => import(/* webpackChunkName: "AddProduct" */ '../views/Vendor/Dashboard/AddProduct'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'edit-product/:id',
                        name: 'EditProduct',
                        component: () => import(/* webpackChunkName: "EditProduct" */ '../views/Vendor/Dashboard/EditProduct'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'orders',
                        name: 'DashboardOrders',
                        component: () => import(/* webpackChunkName: "Orders" */ '../views/Vendor/Dashboard/Orders'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'order-details/:id',
                        name: 'OrderDetails',
                        component: () => import(/* webpackChunkName: "OrderDetails" */ '../views/Vendor/Dashboard/OrderDetails'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'financial-records',
                        name: 'financial-records',
                        component: () => import(/* webpackChunkName: "FinancialRecords" */ '../views/Vendor/Dashboard/FinancialRecords'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'statistics',
                        name: 'Statistics',
                        component: () => import(/* webpackChunkName: "Statistics" */ '../views/Vendor/Dashboard/Statistics'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'plan-details',
                        name: 'PlanDetails',
                        component: () => import(/* webpackChunkName: "PlanDetails" */ '../views/Vendor/Dashboard/PlanDetails'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'renew-plan',
                        name: 'RenewPlan',
                        component: () => import(/* webpackChunkName: "RenewPlan" */ '../views/Vendor/Dashboard/ReNewPlan'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'profile',
                        name: 'Profile',
                        component: () => import(/* webpackChunkName: "Profile" */ '../views/Vendor/Dashboard/Profile'),
                        meta: {
                            requiresAuth: true,
                            userType: 'vendor'
                        },
                    },
                    {
                        path: 'Profiletwo',
                        name: 'Profiletwo',
                        component: () => import(/* webpackChunkName: "Profile" */ '../views/Vendor/Dashboard/Profiletwo'),
                    }

                ]
            },
        ]
    },
    {
        path: '/',
        name: 'Store',
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Store'),
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                path: 'sign-up',
                name: 'create-account',
                component: () => import(/* webpackChunkName: "create-account" */ '../views/Store/SignUp.vue'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'login',
                name: 'loginStore',
                component: () => import(/* webpackChunkName: "loginStore" */ '../views/Store/Login.vue'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'forget-password',
                name: 'ForgetPasswordStore',
                component: () => import(/* webpackChunkName: "ForgetPasswordStore" */ '../views/Store/ForgetPassword.vue'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'reset-password',
                name: 'ResetPasswordStore',
                component: () => import(/* webpackChunkName: "ResetPasswordsStore" */ '../views/Store/ResetPassword.vue'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: '',
                name: 'StoreHome',
                component: () => import(/* webpackChunkName: "StoreHome" */ '../views/Store/Home'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'categories/:id',
                name: 'Category',
                component: () => import(/* webpackChunkName: "Category" */ '../views/Store/Category'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'categories/:id/:subCategory',
                name: 'subCategory',
                component: () => import(/* webpackChunkName: "SubCategory" */ '../views/Store/Category/SubCategory'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'products/:id',
                name: 'productDetails',
                component: () => import(/* webpackChunkName: "ProductDetails" */ '../views/Store/ProductDetails'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'results',
                name: 'searchResults',
                component: () => import(/* webpackChunkName: "searchResults" */ '../views/Store/SearchResults.vue'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'cart',
                name: 'Cart',
                component: () => import(/* webpackChunkName: "Cart" */ '../views/Store/Cart'),
                meta: {
                    requiresAuth: true,
                    userType: 'client'
                }
            },
            {
                path: 'platten-stores',
                name: 'plattenStores',
                component: () => import(/* webpackChunkName: "plattenStores" */ '../views/Store/PlattenStores'),
                meta: {
                    requiresAuth: false,
                }
            },
            {
                path: 'platten-stores/:id',
                name: 'storeProfile',
                component: () => import(/* webpackChunkName: "storeProfile" */ '../views/Store/PlattenStores/StoreProfile'),
                meta: {
                    requiresAuth: false,
                }
            },

            {
                path: 'settings',
                name: 'Settings',
                component: () => import(/* webpackChunkName: "plattenStores" */ '../views/Store/Settings'),
                children: [
                    {
                        path: 'my-orders',
                        name: 'myOrders',
                        component: () => import(/* webpackChunkName: "myOrders" */ '../views/Store/Settings/MyOrders'),
                        meta: {
                            requiresAuth: true,
                            userType: 'client'

                        },
                    },
                    {
                        path: 'my-addresses',
                        name: 'myAddresses',
                        component: () => import(/* webpackChunkName: "myAddresses" */ '../views/Store/Settings/MyAddresses'),
                        meta: {
                            requiresAuth: true,
                            userType: 'client'

                        },
                    },
                    {
                        path: 'my-favourites',
                        name: 'MyFavourites',
                        component: () => import(/* webpackChunkName: "MyFavourites" */ '../views/Store/Settings/MyFavourites'),
                        meta: {
                            requiresAuth: true,
                            userType: 'client'

                        },
                    },
                    {
                        path: '',
                        name: 'MyAccount',
                        component: () => import(/* webpackChunkName: "MyAccount" */ '../views/Store/Settings/MyAccount'),
                        meta: {
                            requiresAuth: true,
                            userType: 'client'

                        },
                    },
                ]
            },


            {
                path: 'confirm-order',
                name: 'Orders',
                component: () => import(/* webpackChunkName: "confirm-order" */ '../views/Store/Orders'),
                children: [
                    {
                        path: '',
                        name: 'ShoppingAddress',
                        component: () => import(/* webpackChunkName: "ShoppingAddress" */ '../views/Store/Orders/ShoppingAddress'),
                        meta: {
                            requiresAuth: true,
                            userType: 'client'

                        },
                    },
                    {
                        path: 'payment',
                        name: 'payment',
                        component: () => import(/* webpackChunkName: "payment" */ '../views/Store/Orders/Payment'),
                        meta: {
                            requiresAuth: true,
                            userType: 'client'

                        },
                    },
                    {
                        path: 'completed',
                        name: 'completed',
                        component: () => import(/* webpackChunkName: "completed" */ '../views/Store/Orders/completed'),
                        meta: {
                            requiresAuth: true,
                            userType: 'client'

                        },
                    }

                ]
            }

        ]
    },
    {
        path: '/dashboard',
        name: 'adminDashboard',
        component: () => import(/* webpackChunkName: "adminDashboard" */ '../views/Dashboard/'),
        children: [
            {
                path: '',
                name: 'adminHome',
                component: () => import(/* webpackChunkName: "adminHome" */ '../views/Dashboard/Home.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'login',
                name: 'adminLogin',
                component: () => import(/* webpackChunkName: "adminLogin" */ '../views/Dashboard/Login.vue'),
                meta: {
                    requiresAuth: false,
                    userType: 'admin'
                },
            },
            {
                path: 'profile',
                name: 'adminProfile',
                component: () => import(/* webpackChunkName: "adminOrders" */ '../views/Dashboard/Profile.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'orders',
                name: 'adminOrders',
                component: () => import(/* webpackChunkName: "adminOrders" */ '../views/Dashboard/Orders/Orders.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'pending_orders',
                name: 'adminPendingOrders',
                component: () => import(/* webpackChunkName: "adminOrders" */ '../views/Dashboard/Orders/pending_orders.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'uncompleted_orders',
                name: 'adminUncompletedOrders',
                component: () => import(/* webpackChunkName: "adminOrders" */ '../views/Dashboard/Orders/uncompleted_orders.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'orders/:id',
                name: 'adminOrderDetails',
                component: () => import(/* webpackChunkName: "adminOrderDetails" */ '../views/Dashboard/OrderDetails.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'clients',
                name: 'adminClients',
                component: () => import(/* webpackChunkName: "adminClients" */ '../views/Dashboard/Clients.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'clients/:id',
                name: 'adminClientDetails',
                component: () => import(/* webpackChunkName: "adminClientDetails" */ '../views/Dashboard/ClientDetails.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            // {
            //     path: 'products',
            //     name: 'adminProducts',
            //     component: () => import(/* webpackChunkName: "adminProducts" */ '../views/Dashboard/Products/index'),
            //     meta: {
            //         requiresAuth: true,
            //     },
            // },
            {
                path: 'products/:id',
                name: 'adminEditProduct',
                component: () => import(/* webpackChunkName: "adminProductDetails" */ '../views/Dashboard/Products/EditProduct.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/addProduct',
                name: 'adminAddProduct',
                component: () => import(/* webpackChunkName: "adminAddProduct" */ '../views/Dashboard/Products/AddProduct.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/types',
                name: 'adminProductTypes',
                component: () => import(/* webpackChunkName: "adminProductModels" */ '../views/Dashboard/Products/ProductTypes.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/versions',
                name: 'adminProductVersions',
                component: () => import(/* webpackChunkName: "adminProductModels" */ '../views/Dashboard/Products/ProductVersions.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/models',
                name: 'adminProductModels',
                component: () => import(/* webpackChunkName: "adminProductModels" */ '../views/Dashboard/Products/ProductModels.vue'),

                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/sizes',
                name: 'adminProductSizes',
                component: () => import(/* webpackChunkName: "adminProductSizes" */ '../views/Dashboard/Products/ProductSizes.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/colors',
                name: 'adminProductColors',
                component: () => import(/* webpackChunkName: "adminProductColors" */ '../views/Dashboard/Products/ProductColors.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/screens',
                name: 'adminProductScreens',
                component: () => import(/* webpackChunkName: "adminProductSizes" */ '../views/Dashboard/Products/ProductScreens.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'products/features',
                name: 'adminProductFeatures',
                component: () => import(/* webpackChunkName: "adminProductSizes" */ '../views/Dashboard/Products/ProductFeatures.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'reports',
                name: 'adminReports',
                component: () => import(/* webpackChunkName: "adminReports" */ '../views/Dashboard/Reports.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'

                },
            },
            {
                path: 'newsletter',
                name: 'adminNewsletters',
                component: () => import(/* webpackChunkName: "adminReports" */ '../views/Dashboard/Newsletter.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'

                },
            },
            {
                path: 'marketing',
                name: 'adminMarketing',
                component: () => import(/* webpackChunkName: "adminMarketing" */ '../views/Dashboard/Marketing.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'marketing/add',
                name: 'adminMarketingAdd',
                component: () => import(/* webpackChunkName: "adminMarketingAdd" */ '../views/Dashboard/AddMarketing.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'marketing/:id',
                name: 'adminMarketingEdit',
                component: () => import(/* webpackChunkName: "adminMarketingEdit" */ '../views/Dashboard/EditMarketing.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'settings',
                name: 'adminSettings',
                component: () => import(/* webpackChunkName: "adminSettings" */ '../views/Dashboard/Settings/'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'settings/home-slider',
                name: 'adminHomeSlider',
                component: () => import(/* webpackChunkName: "adminSettings" */ '../views/Dashboard/Settings/HomeSlider.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'settings/advertisements',
                name: 'adminAdvertisements',
                component: () => import(/* webpackChunkName: "adminSettings" */ '../views/Dashboard/Settings/Advertisements.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'settings/categories',
                name: 'adminSettingsCategories',
                component: () => import(/* webpackChunkName: "adminSettingsCategories" */ '../views/Dashboard/Settings/Categories.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/categories/add',
                name: 'adminSettingsCategoriesAdd',
                component: () => import(/* webpackChunkName: "adminSettingsCategoriesAdd" */ '../views/Dashboard/Settings/AddCategory.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/categories/:id',
                name: 'adminSettingsCategoriesEdit',
                component: () => import(/* webpackChunkName: "adminSettingsCategoriesEdit" */ '../views/Dashboard/Settings/EditCategory.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/sub-categories',
                name: 'adminSettingsSubCategories',
                component: () => import(/* webpackChunkName: "adminSettingsSubCategories" */ '../views/Dashboard/Settings/SubCategories.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/sub-categories/edit/:id',
                name: 'adminSettingsSubCategoriesEdit',
                component: () => import(/* webpackChunkName: "adminSettingsSubCategoriesEdit" */ '../views/Dashboard/Settings/EditSubCategory.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }

            },
            {
                path: 'settings/sub-categories/add',
                name: 'adminSettingsSubCategoriesAdd',
                component: () => import(/* webpackChunkName: "adminSettingsSubCategoriesAdd" */ '../views/Dashboard/Settings/AddSubCategory.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },

            {
                path: 'settings/countries',
                name: 'adminSettingsCountries',
                component: () => import(/* webpackChunkName: "adminSettingsCountries" */ '../views/Dashboard/Settings/Countries.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/countries/add',
                name: 'adminSettingsCountriesAdd',
                component: () => import(/* webpackChunkName: "adminSettingsCountriesAdd" */ '../views/Dashboard/Settings/AddCountry.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/countries/:id',
                name: 'adminSettingsCountriesEdit',
                component: () => import(/* webpackChunkName: "adminSettingsCountriesEdit" */ '../views/Dashboard/Settings/EditCountry.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/cities',
                name: 'adminSettingsCities',
                component: () => import(/* webpackChunkName: "adminSettingsCities" */ '../views/Dashboard/Settings/Cities.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/cities/add',
                name: 'adminSettingsCitiesAdd',
                component: () => import(/* webpackChunkName: "adminSettingsCitiesAdd" */ '../views/Dashboard/Settings/AddCity.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/cities/:id',
                name: 'adminSettingsCitiesEdit',
                component: () => import(/* webpackChunkName: "adminSettingsCitiesEdit" */ '../views/Dashboard/Settings/EditCity.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/brands',
                name: 'adminBrands',
                component: () => import(/* webpackChunkName: "adminBrands" */ '../views/Dashboard/Settings/Brands.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/brands/add',
                name: 'adminBrandsAdd',
                component: () => import(/* webpackChunkName: "adminBrandsAdd" */ '../views/Dashboard/Settings/AddBrand.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/brands/:id',
                name: 'adminBrandsEdit',
                component: () => import(/* webpackChunkName: "adminBrandsEdit" */ '../views/Dashboard/Settings/EditBrand.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/subscriptions',
                name: 'adminSubscriptions',
                component: () => import(/* webpackChunkName: "adminSubscriptions" */ '../views/Dashboard/Settings/Subscriptions.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/subscriptions/add',
                name: 'adminSubscriptionsAdd',
                component: () => import(/* webpackChunkName: "adminSubscriptionsAdd" */ '../views/Dashboard/Settings/AddSubscription.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/subscriptions/:id',
                name: 'adminSubscriptionsEdit',
                component: () => import(/* webpackChunkName: "adminSubscriptionsEdit" */ '../views/Dashboard/Settings/EditSubscription.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/banks',
                name: 'adminBanks',
                component: () => import(/* webpackChunkName: "adminBanks" */ '../views/Dashboard/Settings/Banks.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/banks/add',
                name: 'adminBanksAdd',
                component: () => import(/* webpackChunkName: "adminBanksAdd" */ '../views/Dashboard/Settings/AddBank.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'settings/banks/:id',
                name: 'adminBanksEdit',
                component: () => import(/* webpackChunkName: "adminBanksEdit" */ '../views/Dashboard/Settings/EditBank.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'store/about-us',
                name: 'adminStoreAboutUs',
                component: () => import(/* webpackChunkName: "adminStoreAboutUs" */ '../views/Dashboard/Store/AboutUs.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'store/privacy-policy',
                name: 'adminStorePrivacyPolicy',
                component: () => import(/* webpackChunkName: "adminStorePrivacyPolicy" */ '../views/Dashboard/Store/PrivacyPolicy.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'store/shipping-policy',
                name: 'adminStoreShippingPolicy',
                component: () => import(/* webpackChunkName: "adminStoreShippingPolicy" */ '../views/Dashboard/Store/ShippingPolicy.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'store/faq',
                name: 'adminStoreFaq',
                component: () => import(/* webpackChunkName: "adminStoreFaq" */ '../views/Dashboard/Store/Faq.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            },
            {
                path: 'traders',
                name: 'adminTraders',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/Traders.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                path: 'showTrader/:id',
                name: 'adminShowTrader',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/ShowTrader'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
            },
            {
                                            path: 'TradersReports',
                                            name: 'TradersReports',
                                            component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersReports.vue'),
                                            meta: {
                                                requiresAuth: true,
                                                userType: 'admin'
                                            }
                                                    },
            {
                path: 'TradersReviews',
                name: 'TradersReviews',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersReviews.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'TradersGroups',
                name: 'TradersGroups',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersGroups.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'TradersProperties',
                name: 'TradersProperties',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersProperties.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'TradersProducts',
                name: 'TradersProducts',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersProducts.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'TradersTreatments',
                name: 'TradersTreatments',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersTreatments.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'TradersPayments',
                name: 'TradersPayments',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersPayments.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'TradersSubscriptions',
                name: 'TradersSubscriptions',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersSubscriptions.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'TradersSettings',
                name: 'TradersSettings',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/Traders/TradersSettings.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },

            {
                path: 'generalMarketing',
                name: 'generalMarketing',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/general.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'integrationMarketing',
                name: 'integrationMarketing',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/integration.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                path: 'discounts',
                name: 'discounts',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/discounts.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                },
                        },
            {
                path: 'discounts/home',
                name: 'discountsHome',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/Discounts/Home.vue'),
                meta: {
                    requiresAuth: false,
                    userType: 'admin'

            },
            },
            {
                path: 'discounts/add',
                name: 'discountsAdd',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/Discounts/Add.vue'),
                meta: {
                    requiresAuth: false,
                    userType: 'admin'

            },
            },
            {
                            path: 'discounts/update/:id',
                            name: 'discountsUpdate',
                            component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/Discounts/Update.vue'),
                            meta: {
                                requiresAuth: false,
                                userType: 'admin'

                        },
                        },
            {
                path: 'vouchers',
                name: 'vouchers',
                component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/vouchers.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
                        },
            {
                            path: 'vouchers/home',
                            name: 'vouchersHome',
                            component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/Vouchers/Home.vue'),
                            meta: {
                                requiresAuth: false,
                                userType: 'admin'

                        },
                        },
            {
                 path: 'vouchers/add',
                 name: 'vouchersAdd',
                 component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/Vouchers/Add.vue'),
                 meta: {
                     requiresAuth: false,
                     userType: 'admin'

             },
             },
             {
                             path: 'vouchers/update/:id',
                             name: 'vouchersUpdate',
                             component: () => import(/* webpackChunkName: "adminTraders" */ '../views/Dashboard/marketing/Vouchers/Update.vue'),
                             meta: {
                                 requiresAuth: false,
                                 userType: 'admin'

                         },
                         },

            {
                path: 'apps-and-services',
                name: 'adminAppsAndServices',
                component: () => import(/* webpackChunkName: "adminAppsAndServices" */ '../views/Dashboard/AppsAndServices.vue'),
                meta: {
                    requiresAuth: true,
                    userType: 'admin'
                }
            }

        ]
    },

    {
        path: '/help',
        name: 'help',
        component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/sell-on-platten',
        name: 'SellOnPlatten',
        component: () => import(/* webpackChunkName: "SellOnPlatten" */ '../views/SellOnPlatten.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/shipping-with-platten',
        name: 'ShippingWithPlatten',
        component: () => import(/* webpackChunkName: "ShippingWithPlatten" */ '../views/ShippingWithPlatten.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import(/* webpackChunkName: "Terms" */ '../views/TermsAndConditions.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import(/* webpackChunkName: "Terms" */ '../views/about-us.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "Terms" */ '../views/privacy-policy.vue'),
        meta: {
            requiresAuth: false,
        }
    }
    ,
    {
        path: '/shipping-policy',
        name: 'shipping-policy',
        component: () => import(/* webpackChunkName: "Shipping" */ '../views/shipping-policy.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/404')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = () => store.getters.isAuthenticated;
    const userType = store.getters.userType;

    console.log(to.matched.some((record) => record.meta['requiresAuth']))
    if (to.matched.some((record) => record.meta['requiresAuth'])) {
            if (to.meta?.userType === 'admin') {
                if (isAuthenticated() && to.meta?.userType === userType) {
                    to.name === 'adminLogin' ? next('/dashboard') : next();
                }else {
                    next('/dashboard/login');
                }
            } else if (to.meta?.userType === 'vendor') {
                if (isAuthenticated() && to.meta?.userType === userType) {
                    to.name === 'login' ? next('/vendor/dashboard') : next();
                }else {
                    next("/vendor");
                }
            } else if(to.meta?.userType === 'client') {
                if (isAuthenticated() && to.meta?.userType === userType) {
                    to.name === 'login' ? next('/') : next();
                }else {
                    next("/");
                }
            }

    } else {
        if(to.meta?.userType === 'admin') {
            to.name === 'adminLogin' && isAuthenticated() ? next('/dashboard') : next();
        }else if (to.meta?.userType === 'vendor') {
            to.name === 'login' && isAuthenticated() ? next('/vendor/dashboard') : next();
        }else {
            next();
        }
    }
    // }
});
export default router
