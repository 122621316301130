import {subscribePlan} from "@/services/Subscriptions";

export default {
    state: {
        subscription: {}
    },
    getters: {
        subscription: state => state.subscription
    },
    mutations: {
        SET_SUBSCRIPTION(state,data){
            state.subscription = data
        }
    },
    actions: {
        saveSubscriptionInfo({commit},data){
            commit('SET_SUBSCRIPTION', data)
        },

        async submitSubscription({getters}){
             await subscribePlan(getters.subscription)
        }

    }
}