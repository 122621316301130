import request from "@/helpers/request";

export function storeCategory(data){
    return request(
        {
            url: 'api/general/categories/store',
            method: 'post',
            data
        }
    )
}

export function getCategory(id){
    return request(
        {
            url: `api/general/categories/show?category_id=${id}`,
            method: 'get'
        }
    )
}

export function updateCategory(data){
    return request(
        {
            url: 'api/general/categories/update',
            method: 'post',
            data
        }
    )
}

export function deleteCategory(id){
    return request(
        {
            url: `api/general/categories/destroy`,
            method: 'post',
            data: {
                category_id: id
            }
        }
    )
}

 //SubCategories

export function storeSubCategory(data){
    return request(
        {
            url: 'api/general/sub_categories/store',
            method: 'post',
            data
        }
    )
}

export function getSubCategory(id){
    return request(
        {
            url: `api/general/sub_categories/show?sub_category_id=${id}`,
            method: 'get'
        }
    )
}

export function updateSubCategory(data){
    return request(
        {
            url: 'api/general/sub_categories/update',
            method: 'post',
            data
        }
    )
}

export function deleteSubCategory(id){
    return request(
        {
            url: `api/general/sub_categories/destroy`,
            method: 'post',
            data: {
                sub_category_id: id
            }
        }
    )
}

//Countries

export function storeCountry(data){
    return request(
        {
            url: 'api/general/countries/store',
            method: 'post',
            data
        }
    )
}

export function getCountry(id){
    return request(
        {
            url: `api/general/countries/show?country_id=${id}`,
            method: 'get'
        }
    )
}

export function updateCountry(data){
    return request(
        {
            url: 'api/general/countries/update',
            method: 'post',
            data
        }
    )
}

export function deleteCountry(id){
    return request(
        {
            url: `api/general/countries/destroy`,
            method: 'post',
            data: {
                country_id: id
            }
        }
    )
}


//Cities

export function storeCity(data){
    return request(
        {
            url: 'api/general/cities/store',
            method: 'post',
            data
        }
    )
}

export function getCity(id){
    return request(
        {
            url: `api/general/cities/show?city_id=${id}`,
            method: 'get'
        }
    )
}

export function updateCity(data){
    return request(
        {
            url: 'api/general/cities/update',
            method: 'post',
            data
        }
    )
}

export function deleteCity(id){
    return request(
        {
            url: `api/general/cities/destroy`,
            method: 'post',
            data: {
                city_id: id
            }
        }
    )
}

//Brands

export function storeBrand(data){
    return request(
        {
            url: 'api/general/brands/store',
            method: 'post',
            data
        }
    )
}

export function getBrand(id){
    return request(
        {
            url: `api/general/brands/show?brand_id=${id}`,
            method: 'get'
        }
    )
}

export function updateBrand(data){
    return request(
        {
            url: 'api/general/brands/update',
            method: 'post',
            data
        }
    )
}

export function deleteBrand(id){
    return request(
        {
            url: `api/general/brands/destroy`,
            method: 'post',
            data: {
                brand_id: id
            }
        }
    )
}


//Subscriptions

export function storeSubscription(data){
    return request(
        {
            url: 'api/general/subscriptions/store',
            method: 'post',
            data
        }
    )
}

export function getSubscription(id){
    return request(
        {
            url: `api/general/subscriptions/show?subscription_id=${id}`,
            method: 'get'
        }
    )
}

export function updateSubscription(data){
    return request(
        {
            url: 'api/general/subscriptions/update',
            method: 'post',
            data
        }
    )
}

export function deleteSubscription(id){
return request(
        {
            url: `api/general/subscriptions/destroy`,
            method: 'post',
            data: {
                subscription_id: id
            }
        }
    )
}

//Banks
export function storeBank(data){
    return request(
        {
            url: 'api/general/banks/store',
            method: 'post',
            data
        }
    )
}

export function getBank(id){
    return request(
        {
            url: `api/general/banks/show?bank_id=${id}`,
            method: 'get'
        }
    )
}

export function updateBank(data){
    return request(
        {
            url: 'api/general/banks/update',
            method: 'post',
            data
        }
    )
}

export function deleteBank(id){
    return request(
        {
            url: `api/general/banks/destroy`,
            method: 'post',
            data: {
                bank_id: id
            }
        }
    )
}

export function getVendors() {
    return request(
        {
            url: 'api/vendors/index',
            method: 'get'
        }
    )
}

export function getVendor(id) {
    return request(
        {
            url: `api/vendors/show?vendor_id=${id}`,
            method: 'get'
        }
    )
}

export function storeVendor(data) {
    return request(
        {
            url: 'api/vendors/store',
            method: 'post',
            data
        }
    )
}

export function updateVendor(data) {
    return request(
        {
            url: 'api/vendors/update',
            method: 'post',
            data
        }
    )
}

export function deleteVendor(id) {
    return request(
        {
            url: `api/vendors/destroy`,
            method: 'post',
            data: {
                vendor_id: id
            }
        }
    )
}


export function getSettings(params) {
    return request(
        {
            url: 'api/general/settings',
            method: 'get',
            params
        }
    )
}

export function updateSettings(data) {
    return request(
        {
            url: 'api/general/settings/update',
            method: 'post',
            data
        }
    )
}

export function geDashboardStatistics() {
    return request(
        {
            url: 'api/dashboard/statistics',
            method: 'get'
        }
    )
}

export function sendNotification(data) {
    return request(
        {
            url: 'api/notifications/send',
            method: 'post',
            data
        }
    )
}

export function getCustomers() {
    return request(
        {
            url: 'api/customers',
            method: 'get'
        }
    )
}
export function getAllCustomers(params) {
    return request(
        {
            url: 'api/customers',
            method: 'get',
            params
        }
    )
}

export function getFaqs(params) {
    return request(
        {
            url: 'api/general/faqs',
            method: 'get',
            params
        }
    )
}

export function storeFaq(data) {
    return request(
        {
            url: 'api/general/faqs/store',
            method: 'post',
            data
        }
    )
}

export function getFaq(id) {
    return request(
        {
            url: `api/general/faqs/show?faq_id=${id}`,
            method: 'get'
        }
    )
}

export function updateFaq(data) {
    return request(
        {
            url: 'api/general/faqs/update',
            method: 'post',
            data
        }
    )
}

export function deleteFaq(id) {
    return request(
        {
            url: `api/general/faqs/destroy`,
            method: 'post',
            data: {
                faq_id: id
            }
        }
    )
}

export function storeAdvertisements(data) {
    return request(
        {
            url: 'api/general/advertisements/store',
            method: 'post',
            data
        }
    )
}

export function getAdvertisements(params) {
    return request(
        {
            url: 'api/general/advertisements?q&per_page=10',
            method: 'get',
            params
        }
    )
}

export function getReports(params,type) {
    return request(
        {
            url: `api/employees/reports/${type}?q=`,
            method: 'get',
            params
        }
    )
}

export function updateAdvertisement(data) {
    return request(
        {
            url: 'api/general/advertisements/update',
            method: 'post',
            data
        }
    )
}

export function deleteAdvertisement(id) {
    return request(
        {
            url: `api/general/advertisements/destroy`,
            method: 'post',
            data: {
                advertisement_id: id
            }
        }
    )
}
export function getVendorsSubscription() {
    return request(
        {
            url: 'api/vendors/vendor_subscriptions',
            method: 'get'
        }
    )
}