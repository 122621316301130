import { createStore } from 'vuex'
import user from './user';
import general from "./general";
import subscribeInfo from "./subscribeInfo";
import cartProducts from "./cartProducts";
import createPersistedState from "vuex-persistedstate";
import address from "@/store/address";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    general,
    subscribeInfo,
    address,
    cartProducts
  },
  plugins: [createPersistedState()],
})
