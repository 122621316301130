import {getCategories, getCities, getCountries} from "@/services/general";
import {getSettings} from "@/services/employee/general";

export default {
    state: {
        countries: [],
        cities: [],
        categories: [],
        contactInfo: [],
        pages: []
    },
    getters: {
        countries: state => state.countries,
        cities: state => state.cities,
        categories: state => state.categories,
        contactInfo: state => state.contactInfo,
        pages: state => state.pages,
    },
    mutations: {
        SET_COUNTRIES(state,data){
            state.countries = data
        },
        SET_CITIES(state,data){
            state.cities = data
        },
        SET_CATEGORIES(state,data){
            state.categories = data
        },
        SET_CONTACT_INFO(state,data) {
            state.contactInfo = data
        },
        SET_PAGES(state,data) {
            state.pages = data
        }

    },
    actions: {
        async fetchCountries({commit}){
            const countries = await getCountries()
            if(countries.data.status.code === 200){
                commit('SET_COUNTRIES',countries.data.data.Countries)
            }
        },
        async fetchCities({commit}){
            const cities = await getCities()
            if(cities.data.status.code === 200){
                commit('SET_CITIES',cities.data.data.Cities)
            }
        },
        async fetchCategories({commit}){
            const categories = await getCategories({per_page: 5})
            if(categories.data.status.code === 200){
                commit('SET_CATEGORIES',categories.data.data.Categories)
            }
        },
        async fetchSettings({commit}) {
            const socialMediaSettings = await getSettings({per_page: 10,type: 2})
            const {data, status} = socialMediaSettings.data;
            if(status.code === 200) {
                commit('SET_CONTACT_INFO', data.Settings)
            }
        },
        async fetchFooterPages({commit}) {
            const Settings = await getSettings({per_page: 4,type: 1})
            const {data, status} = Settings.data;
            if(status.code === 200) {
                commit('SET_PAGES', data.Settings)
            }
        },

    }
}