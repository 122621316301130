import request from '@/helpers/request';

export function login(data) {
    return request(
        {
            method:'post',
            url: 'api/employees/login',
            data
        }
    )
}
export function getProfile(){
    return request(
        {
            url: 'api/employees/show',
            method:'get'
        }
    )
}
export function updateProfile(data){
    return request(
        {
            method: 'post',
            url: 'api/employees/update',
            data
        }
    )
}

export function logoutEmployee() {
    return request(
        {
            method: 'post',
            url:'api/employees/logout',
        }
    )
}
export function changePassword(data){
    return request(
        {
            url: 'api/employees/update/password',
            method: 'post',
            data
        }
    )
}