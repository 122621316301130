import { createApp } from 'vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';

import App from './App.vue'
import i18n from './i18n'
import Toast, { POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';


// Prevents toasts of the same type from appearing simultaneously, discarding duplicates
const filterBeforeCreate = (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
        // Returning false discards the toast
        return false;
    }
    // You can modify the toast if you want
    return toast;
}

const options = {
    timeout: 2000,
    position: POSITION.TOP_CENTER,
    filterBeforeCreate
};

createApp(App).use(i18n).use(store).use(router).use(Toast, options).component('v-select', vSelect).mount('#app')
