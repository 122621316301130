import {addToCart, getCartProducts, updateCart} from "@/services/customer/cart";

export default {
    state: {
        cartProducts: [],
        cartTotal: 0,
        isFetched: false,
    },
    getters: {
        cartProducts: state => state.cartProducts,
        isCartFetched: state => state.isFetched,
        cartItemsCount: (state) => {
            // let total = 0;
            // state.cartProducts.forEach(item => {
            //     total += (item.quantity)
            // })
            // return total
            return state.cartTotal
        },
    },
    mutations: {
        SET_CART(state, data) {
            state.cartProducts = data
        },
        SET_CART_TOTAL(state, data) {
            state.cartTotal = data
        },
        SET_IS_FETCHED(state, data) {
            state.isFetched = data
        },
        PUSH_PRODUCT_TO_CART(state, product) {
            state.cartProducts.push(product)
        },
        UPDATE_QUANTITY(state, data) {
            const cart_Item = state.cartProducts.find(item => item.product_id === data.product_id);
            cart_Item.quantity = data.quantity;
        },
        DELETE_FROM_CART(state, cart_id) {
            const index = state.cartProducts.findIndex(item => item.id ===cart_id)
            console.log(cart_id,index)
            if (index !== -1) {
                state.cartProducts.splice(index, 1)
            }
        },
        DROP_ALL_FROM_CART(state) {
            state.cartProducts = [];
            state.cartTotal = 0;
        },
    },
    actions: {
        async fetchCart({commit}) {
            const result = await getCartProducts()
            const {data, status,paging} = result.data;
            if (status.code === 200) {
                commit("SET_CART", data.Carts)
                commit("SET_IS_FETCHED", true)
                commit("SET_CART_TOTAL", paging.Carts.total)

            }
        },
        async addToCart({commit}, product) {
            commit('PUSH_PRODUCT_TO_CART', {...product, product_id: product.id})
            await addToCart({product_id: product.id, quantity: product.quantity})
        },
        async updateCartProduct({commit, state}, product) {
            const cart_item = state.cartProducts.find(item => item.id === product.cart_id)
            if (cart_item) {
                commit('UPDATE_QUANTITY', product)
                commit('SET_CART_TOTAL', state.cartTotal + 1)
                await updateCart(product)
            }
        },
        handleDeleteFromCart({commit}, cart_id){
            commit('DELETE_FROM_CART', cart_id)
        },
        handleDropAllFromCart({commit}){
            commit('DROP_ALL_FROM_CART')

        }

    },
}