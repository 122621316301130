import request from '@/helpers/request';

export function loginCustomer(data) {
    return request(
        {
            method:'post',
            url: 'api/customers/login',
            data
        }
    )
}

export function registerCustomer(data) {
    return request(
        {
            method: 'post',
            url:'api/customers/register',
            data
        }
    )
}

export function getCustomerProfile(){
    return request(
        {
            url: 'api/customers/me',
            method:'get'
        }
    )
}

export function updateCustomerProfile(data){
    return request(
        {
            method: 'post',
            url: 'api/customers/update',
            data
        }
    )
}

export function forgetCustomerPassword(data) {
    return request(
        {
            url: 'api/customers/password/forget',
            method:'post',
            data
        }
    )
}

export function resetCustomerPassword(data){
    return request(
        {
            url: 'api/customers/password/reset',
            method: 'post',
            data
        }
    )
}

export function changeCustomerPassword(data){
    return request(
        {
            url: 'api/customers/update/password',
            method: 'post',
            data
        }
    )
}

export function logoutCustomer() {
    return request(
        {
            method: 'post',
            url:'api/customers/logout',
        }
    )
}