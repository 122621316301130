import {logout, register} from "@/services/auth";
import {useToast} from "vue-toastification";
import {createI18n} from "vue-i18n";
import router from "@/router";
import {logoutCustomer, registerCustomer} from "@/services/customer/auth";
import {logoutEmployee} from "@/services/employee/auth";

const i18n = createI18n({
    legacy: true,
    locale: 'ar',
    messages: {
        'ar': {
            "registerError": "البريد الإلكتروني الذي أدخلته مُستخدم من قبل",
            "generalError": "حدث خطأ,الرجاء المحاولة مرة أخرى",

        }
    }
})

const {t} = i18n.global

export default {
    state: {
        isLoggedIn: false,
        userData: null,
        userType: null,
    },
    getters: {
        user: state => state.userData,
        isAuthenticated: state => state.isLoggedIn,
        userType: state => state.userType || 'client'
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
            state.isLoggedIn = value;
        },
        SET_USER(state, data) {
            state.userData = data;
        },
        SET_USER_TYPE(state, data) {
            state.userType = data;
        }
    },
    actions: {
        async createUser({commit}, data) { // handle vendor login
            try {
                const result = await register(data)
                const {status} = result.data;
                if (status.code === 200) {
                    const {Login, Vendor} = result.data.data;
                    localStorage.setItem('vendor_token', Login['access_token']);
                    await commit("SET_USER", Vendor);
                    await commit("SET_LOGGED_IN", true);
                    await commit("SET_USER_TYPE", 'vendor');
                    await router.push('/vendor/dashboard/profile')
                } else {
                    useToast().error(status.message[0])
                }
            } catch (e) {
                console.error(e)
                useToast().error(t('generalError'))
            }
        },
        async createCustomer({commit}, data) { // handle customer login
            try {
                const result = await registerCustomer(data)
                const {status} = result.data;
                if (status.code === 200) {
                    const {Login, Customer} = result.data.data;
                    localStorage.setItem('customer_token', Login['access_token']);
                    await commit("SET_USER", Customer);
                    await commit("SET_LOGGED_IN", true);
                    await commit("SET_USER_TYPE", 'client');
                    await router.push('/')
                } else {
                    useToast().error(t('registerError'))
                }
            } catch (e) {
                console.error(e)
                useToast().error(t('generalError'))
            }
        },
        storeUser({commit}, user) {
            commit("SET_LOGGED_IN", user !== null);
            user ? commit("SET_USER", user) : commit("SET_USER", null);
        },
        async handleLogoutEmployee({commit}) {
        localStorage.removeItem('access_token');
                            commit("SET_LOGGED_IN", false);
                            commit("SET_USER", null);
                            commit('SET_USER_TYPE', null)
                            router.push('/dashboard/login')
//            logoutEmployee().then(result => {
//                const {status} = result.data;
//                if (status.code === 200) {
//                    localStorage.removeItem('access_token');
//                    commit("SET_LOGGED_IN", false);
//                    commit("SET_USER", null);
//                    commit('SET_USER_TYPE', null)
//                    router.push('/dashboard/login')
//                }
//            })
        },

        setUserType({commit}, type) {
            console.log('userType', type)
          commit('SET_USER_TYPE', type)
        },
        async destroyUser({commit}) {
        localStorage.removeItem('vendor_token')
                            commit('SET_USER', null)
                            commit('SET_LOGGED_IN', false)
                            commit('SET_USER_TYPE', null)
                            router.push('/vendor/login')
//            logout().then(result => {
//                const {status} = result.data
//                if (status.code === 200) {
//                    localStorage.removeItem('vendor_token')
//                    commit('SET_USER', null)
//                    commit('SET_LOGGED_IN', false)
//                    commit('SET_USER_TYPE', null)
//                    router.push('/vendor/login')
//                }
//            }).catch(() => {
//                useToast().error(t('generalError'))
//            })
        },
        async logoutCustomer({commit}) {
        localStorage.removeItem('access_token')
                            commit('SET_USER', null)
                            commit('SET_LOGGED_IN', false)
                            commit('SET_USER_TYPE', null)
                            router.push('/login')
//            logoutCustomer().then(result => {
//                const {status} = result.status
//                if (status.code === 200) {
//                    localStorage.removeItem('access_token')
//                    commit('SET_USER', null)
//                    commit('SET_LOGGED_IN', false)
//                    commit('SET_USER_TYPE', null)
//                    router.push('/login')
//                }
//            }).catch(() => {
//                useToast().error(t('generalError'))
//            })
        }
    }
}