import request from '@/helpers/request';

export function login(data) {
    return request(
        {
            method:'post',
            url: 'api/vendors/login',
            data
        }
    )
}

export function register(data) {
    return request(
        {
            method: 'post',
            url:'api/vendors/register',
            data
        }
    )
}

export function getProfile(){
    return request(
        {
            url: 'api/vendors/me',
            method:'get'
        }
    )
}

export function updateProfile(data){
    return request(
        {
            method: 'post',
            url: 'api/vendors/update',
            data
        }
    )
}

export function forgetPassword(data) {
    return request(
        {
            url: 'api/vendors/password/forget',
            method:'post',
            data
        }
    )
}

export function resetPassword(data){
    return request(
        {
            url: 'api/vendors/password/reset',
            method: 'post',
            data
        }
    )
}
export function changeVendorPassword(data){
    return request(
        {
            url: 'api/vendors/update/password',
            method: 'post',
            data
        }
    )
}

export function logout() {
    return request(
        {
            method: 'post',
            url:'api/vendors/logout',
        }
    )
}